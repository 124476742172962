import React from 'react';
import css from './SectionFooter.module.css';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '../../../../util/reactIntl';
import { IconSocialMediaFacebook, IconSocialMediaInstagram } from '../../../../components';
import IconSocialMediaTiktok from '../../../../components/IconSocialMediaTiktok/IconSocialMediaTiktok';

const SectionFooter = () => {
  return (
    <div className={css.footer}>
      <div className={css.socials}>
        <a
          href="https://www.facebook.com/profile.php?id=61557355707435"
          style={{ margin: '0 10px 10px 0' }}
          target="_blank"
          aria-label="Link to Wheelplace's Facebook account"
        >
          <IconSocialMediaFacebook />
        </a>
        <a
          href="https://www.instagram.com/wheelplace_official/"
          target="_blank"
          aria-label="Link to Wheelplace's Instagram account"
          style={{ margin: '0 10px 10px 0' }}
        >
          <IconSocialMediaInstagram />
        </a>
        <a
          href="https://www.tiktok.com/@wheelplace_official"
          target="_blank"
          aria-label="Link to Wheelplace's TikTok account"
          style={{ color: 'white' }}
        >
          <IconSocialMediaTiktok />
        </a>
      </div>
      <div className={css.col2}>
        <Link to="/p/about">
          <FormattedMessage id="Footer.aboutUs" />
        </Link>
        <Link to="/privacy-policy">
          <FormattedMessage id="Footer.privacyPolicy" />
        </Link>
        <Link to="/terms-of-service">
          <FormattedMessage id="Footer.termsOfService" />
        </Link>
        <Link to="/p/cookies">
          <FormattedMessage id="Footer.cookies" />
        </Link>
        <Link to="/p/faq">
          <FormattedMessage id="Footer.faq" />
        </Link>
      </div>
    </div>
  );
};

export default SectionFooter;
